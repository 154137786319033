import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from '@ultra/core/auth';
import { RolesGroupGuard } from '@ultra/core/guards';
import { UserRoleName } from '@ultra/core/permissions';
import { ClientVersionGuard } from '@ultra/shared';

import { publicStoreAuthGuard } from './auth/public-store-auth-guard';

const STORE_ALLOWED_USER_ROLES_GROUP: UserRoleName[] = [UserRoleName.GAME_STORE_USER];

export const routes: Routes = [
  {
    path: 'store',
    canActivateChild: [publicStoreAuthGuard],
    loadChildren: () => import('./game-store/game-store-routing.module').then((m) => m.GameStoreRoutingModule),
  },
  {
    path: 'your-library',
    canActivate: [authGuard, RolesGroupGuard, ClientVersionGuard],
    data: {
      rolesGroup: STORE_ALLOWED_USER_ROLES_GROUP,
    },
    loadComponent: () => import('./library-games/library-games.component').then((m) => m.LibraryGamesComponent),
  },
  {
    path: 'game-search',
    canActivate: [publicStoreAuthGuard],
    loadComponent: () => import('./game-search/game-search-layout.component').then((m) => m.GameSearchLayoutComponent),
  },
  {
    path: 'get/:gameId/:gameFactoryId',
    canActivate: [authGuard, ClientVersionGuard],
    loadComponent: () => import('./install/install.component').then((m) => m.InstallComponent),
  },
  { path: '', redirectTo: 'store', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
